import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import inheritFromMGW from './customDetector';
import getLangFromQuery from './customDetectorQuery';
import getLangFromBrowser from './getLangFromBrowser';

import * as dk from './translations/dk/dk.json';
import * as de from './translations/de/de.json';
import * as uk from './translations/uk/uk.json';
import * as fi from './translations/fi/fi.json';
import * as fr from './translations/fr/fr.json';
import * as nl from './translations/nl/nl.json';
import * as nlbe from './translations/nlbe/nlbe.json';
import * as no from './translations/no/no.json';
import * as se from './translations/se/se.json';
import * as frbe from './translations/frbe/frbe.json';

const customDetector = new LanguageDetector();
customDetector.addDetector(getLangFromQuery);
customDetector.addDetector(inheritFromMGW);
customDetector.addDetector(getLangFromBrowser);

const detectOptions = {
  order: ['getLangFromQuery', 'localStorage', 'inheritFromMGW', 'getLangFromBrowser'],
};

i18n
  .use(customDetector)
  .use(initReactI18next)
  .init({
    detection: detectOptions,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      dk,
      de,
      uk,
      fi,
      fr,
      frbe,
      nl,
      nlbe,
      no,
      se,
    },
    whitelist: ['dk', 'de', 'uk', 'fi', 'fr', 'frbe', 'nl', 'nlbe', 'no', 'se'],
    fallbackLng: 'se',
    defaultNS: 'default',
    keySeparator: false,
    react: {
      useSuspense: true,
    },
  });

export default i18n;
