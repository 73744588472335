import React from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner } from 'mypages-library';
import { t } from 'i18n/translate';
import { useRequestToken } from 'common/hooks/useRequestToken';
import { useAlternateSubsidiary } from 'common/hooks/useAlternateSubsidiary';

import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import styles from './MultiFactorAuthentication.module.scss';

const minutes = '15';
function UserLocked({ setRequestView, email, dataResponseCtrl: { setDataResponse } }) {
  const { isLoading, error, requestToken } = useRequestToken(setRequestView, setDataResponse);
  const { getPhoneNumber } = useAlternateSubsidiary();
  const phoneNumber = getPhoneNumber();

  return (
    <div data-testid="requestSMS" className={styles['login-wrapper']}>
      <main className={styles['login-content']}>
        {isLoading && <LoadingSpinner isLoading centered size="large" />}
        <SubsidiaryAppLogo />
        <p className={styles.title}>{t('MPL_USER_LOCKED_TITLE')} </p>
        <p className={styles.text}>{t('MPL_USER_LOCKED_WAIT', { minutes })}</p>
        <p className={styles.title}>{t('MPL_USER_LOCKED_VIA_MAIL', { email })} </p>
        {error && <div className={styles['error-message']}>{error}</div>}
        <Button
          text={t('MPL_REQUEST_AGAIN_REQUEST_EMAIL_CODE')}
          className={styles['submit-button']}
          white
          onClick={() => requestToken('email')}
          dataTestId="requestAgainEmail"
        />
        <p className={styles['call-text']}>{t('MPL_REQUEST_AGAIN_OR_CALL')} </p>
        <p className={styles['phone-number']}>{phoneNumber}</p>
        <hr className={styles.divider} />
        <span className={styles['go-back-link']} onClick={() => setRequestView('validateToken')}>
          {t('MPL_CANCEL_LOGIN')}
        </span>
      </main>
    </div>
  );
}

UserLocked.propTypes = {
  setRequestView: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  dataResponseCtrl: PropTypes.shape({ setDataResponse: PropTypes.func.isRequired }).isRequired,
};
export default UserLocked;
