import React from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner } from 'mypages-library';
import { t } from 'i18n/translate';
import { useRequestToken } from 'common/hooks/useRequestToken';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';

import styles from './MultiFactorAuthentication.module.scss';

const minutes = '15';
function OutOfValidationAttempts({ setRequestView, email, dataResponseCtrl: { setDataResponse }, backToLogin }) {
  const { isLoading, error, requestToken } = useRequestToken(setRequestView, setDataResponse);

  return (
    <div id="outOfValidationTokens" className={styles['login-wrapper']}>
      <main className={styles['login-content']}>
        {isLoading && <LoadingSpinner isLoading centered size="large" />}
        <SubsidiaryAppLogo />
        <p className={styles.title}>{t('MPL_TO_MANY_VALIDATION_ATTEMPTS')} </p>
        <p className={styles.text}>{t('MPL_REQUEST_A_NEW_TOKEN', { minutes })}</p>
        <Button
          text={t('MPL_REQUEST_AGAIN_REQUEST_SMS')}
          className={styles['submit-button']}
          white
          onClick={requestToken}
          dataTestId="requestAgainSms"
        />
        <p className={styles.title}>{t('MPL_USER_LOCKED_VIA_MAIL', { email })} </p>
        {error && <div className={styles['error-message']}>{error}</div>}
        <p id="requestAgainEmail" className={styles['missing-token-link']} onClick={() => requestToken('email')}>
          {t('MPL_REQUEST_AGAIN_REQUEST_EMAIL_CODE')}
        </p>
        <hr className={styles.divider} />
        <Button
          flat
          text={t('MPL_CANCEL_LOGIN')}
          className={styles['go-back-link']}
          dataTestId="goBackToLogin"
          onClick={backToLogin}
        />
      </main>
    </div>
  );
}

OutOfValidationAttempts.propTypes = {
  setRequestView: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  dataResponseCtrl: PropTypes.shape({ setDataResponse: PropTypes.func.isRequired }).isRequired,
  backToLogin: PropTypes.func.isRequired,
};
export default OutOfValidationAttempts;
