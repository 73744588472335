import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCountryFromLocale, getLocale } from 'common/languageUtil';
import styles from './AppStore.module.scss';

const AppStore: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language || 'se';
  const locale = getLocale(language).toLowerCase();
  const country = getCountryFromLocale(locale).toLowerCase();
  // Force React to load static images
  let appStore;
  let googlePlay;
  try {
        appStore = require(`images/appstores/apple_${language}.png`).default; // eslint-disable-line
        googlePlay = require(`images/appstores/google_${language}.png`).default; // eslint-disable-line
  } catch {
        appStore = require(`images/appstores/apple_se.png`).default; // eslint-disable-line
        googlePlay = require(`images/appstores/google_se.png`).default; // eslint-disable-line
  }

  return (
    <section className={styles.apps}>
      <a
        href={`https://itunes.apple.com/${country}/app/verisure/id423747572?mt=8`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={appStore} className={styles['app-store-image']} alt="App store" />
      </a>
      <a
        href={`https://play.google.com/store/apps/details?id=com.sdi.mobile.android.verisure&hl=${locale}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={googlePlay} className={styles['google-play-image']} alt="Google Play" />
      </a>
    </section>
  );
};

export default AppStore;
