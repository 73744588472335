import errorCodes from './errorCodes';

export default {
  [errorCodes.AUT_00001]: {
    description: 'MPL_ETC_LOGIN_FAILED_MESSAGE',
  },
  [errorCodes.AUT_00004]: {
    description: 'MPL_ETC_LOGIN_FAILED_MESSAGE',
  },
  [errorCodes.AUT_00008]: {
    description: 'MPL_BLACKLISTED_MESSAGE',
  },
  [errorCodes.NETWORK_ERROR]: {
    description: 'MPL_NETWORK_ERROR',
  },
  [errorCodes.INVALID_TOKEN]: {
    description: 'MPL_INVALID_TOKEN',
  },
  [errorCodes.AUT_00021]: {
    description: 'MPL_REQUEST_LIMIT_REACHED',
  },
  [errorCodes['urn:verisure:webaccount/web-account-already-exists']]: {
    description: 'MPL_CREATE_ACCOUNT_ERROR_ACCOUNT_EXISTS_DESC',
  },
};
