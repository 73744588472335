import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from './Input';

function PasswordInput({
  placeholder,
  onChange,
  hasError,
  value,
  disabled,
  onClick,
  className,
  dataTestId,
  errorMessage,
  maxLength,
  disableLabelAnimation,
  focus,
  autoComplete,
}) {
  const [inputType, setInputType] = useState('password');
  const [viewPasswordIcon, setViewPasswordIcon] = useState('icon-show_password');
  const toggleVisibility = () => {
    const newInputType = inputType === 'password' ? 'text' : 'password';
    setInputType(newInputType);
    const hidePasswordIcon = viewPasswordIcon === 'icon-show_password' ? 'icon-hide_password' : 'icon-show_password';
    setViewPasswordIcon(hidePasswordIcon);
  };

  useEffect(() => {
    if (disabled) {
      setInputType('password');
    }
  }, [disabled]);

  return (
    <Input
      placeholder={placeholder}
      icon={viewPasswordIcon}
      type={inputType}
      maxLength={maxLength}
      value={value}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
      onIconClick={toggleVisibility}
      className={className}
      dataTestId={dataTestId}
      disableLabelAnimation={disableLabelAnimation}
      focus={focus}
      autoComplete={autoComplete}
    />
  );
}

PasswordInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  disableLabelAnimation: PropTypes.bool,
  focus: PropTypes.bool,
  autoComplete: PropTypes.string,
};

PasswordInput.defaultProps = {
  hasError: false,
  value: '',
  disabled: false,
  onClick: () => {},
  className: '',
  dataTestId: null,
  errorMessage: '',
  maxLength: 4,
  disableLabelAnimation: false,
  focus: false,
  autoComplete: 'off',
};

export default PasswordInput;
