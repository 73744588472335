import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TrustDevice from 'components/TrustDevice/TrustDevice';
import { useTrust } from 'common/hooks/useTrust';
import RequestSMS from './RequestSMS';
import ValidateToken from './ValidateToken';
import RequestAgain from './RequestAgain';
import UserLocked from './UserLocked';
import TooManyTokensRequested from './TooManyTokensRequested';
import TooManySMSSent from './TooManySMSSent';
import OutOfValidationAttempts from './OutOfValidationAttempts';
import WrongPhoneNumber from './WrongPhoneNumber';

function MultiFactorAuthentication({ email }) {
  const [requestView, setRequestView] = useState('requestSMS');
  const [dataResponse, setDataResponse] = useState({});

  const goBackToLogin = () => window.location.replace('/login');

  return (
    <>
      {requestView === 'trust' && <TrustDevice useTrust={useTrust} />}
      {requestView === 'requestSMS' && <RequestSMS setRequestView={setRequestView} setDataResponse={setDataResponse} />}
      {requestView === 'requestAgain' && (
        <RequestAgain setRequestView={setRequestView} setDataResponse={setDataResponse} email={email} />
      )}
      {requestView === 'validateToken' && (
        <ValidateToken setRequestView={setRequestView} email={email} mfaResponse={dataResponse} />
      )}
      {requestView === 'userLocked' && (
        <UserLocked
          setRequestView={setRequestView}
          email={email}
          dataResponseCtrl={{ dataResponse, setDataResponse }}
        />
      )}
      {requestView === 'tooManyMfaTokensRequested' && <TooManyTokensRequested backToLogin={goBackToLogin} />}
      {requestView === 'tooManySmsSent' && (
        <TooManySMSSent setRequestView={setRequestView} setDataResponse={setDataResponse} />
      )}
      {requestView === 'wrongPhoneNumber' && (
        <WrongPhoneNumber setRequestView={setRequestView} setDataResponse={setDataResponse} />
      )}
      {requestView === 'outOfValidationAttempts' && (
        <OutOfValidationAttempts
          setRequestView={setRequestView}
          email={email}
          dataResponseCtrl={{ dataResponse, setDataResponse }}
          backToLogin={goBackToLogin}
        />
      )}
    </>
  );
}

MultiFactorAuthentication.propTypes = {
  email: PropTypes.string.isRequired,
};

export default MultiFactorAuthentication;
