import React from 'react';
import { Button } from 'mypages-library';
import { t } from 'i18n/translate';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import styles from './TermsAndCondition.module.scss';

type TermsAndConditionProps = {
  terms: string | null | undefined;
  error: string;
  setShowTerms: (showTerms: boolean) => void;
};

const TermsAndCondition = ({ terms, error, setShowTerms }: TermsAndConditionProps) => {
  if (error) {
    return (
      <div className={styles.errorContainer}>
        <SubsidiaryAppLogo />
        <p>{error}</p>
        <Button text={t('MPL_GO_BACK')} onClick={() => setShowTerms(false)} className={styles.linkText} />
      </div>
    );
  }

  return (
    <>
      <SubsidiaryAppLogo />
      {/* eslint-disable-next-line react/no-danger */}
      {terms && <div className={styles.termsContainer} dangerouslySetInnerHTML={{ __html: terms }} />}
      <button type="button" onClick={() => setShowTerms(false)} className={styles.linkText}>
        {t('MPL_GO_BACK')}
      </button>
    </>
  );
};

export default TermsAndCondition;
