import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Button, LoadingSpinner } from 'mypages-library';
import { useUnblockAccount } from 'common/hooks/useUnblockAccount';
import { getCountryFromLocale, getLanguageFromLocale, getLocale, siteLanguages } from 'common/languageUtil';
import UnsupportedBrowser from 'components/UnsupportedBrowser/UnsupportedBrowser';
import { browserIsUnsupported } from 'components/UnsupportedBrowser/unsupportedBrowserUtils';
import UnblockingSuccessful from 'images/misc/UnblockSuccessful@1x.png';
import UnblockingUnSuccessful from 'images/misc/UnblockUnsuccessful@1x.png';
import CookieInfo from 'components/common/CookieInfo/CookieInfo';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import styles from './UnblockPage.module.scss';

const cookieBannerVisible = localStorage.getItem('cookie-banner-state') !== 'cleared';

function UnblockPage() {
  const [cookieInfoVisible, setCookieInfoVisible] = useState(cookieBannerVisible);

  const { t, i18n } = useTranslation();

  const isUnsupportedBrowser = browserIsUnsupported();
  const { unblockAccount, error, isLoading } = useUnblockAccount();

  const query = new URLSearchParams(window.location.search.substring(1));
  const currentLanguage = i18n.language;
  const queryLanguage = query.get('site');
  useEffect(() => {
    if (queryLanguage && siteLanguages.includes(queryLanguage) && currentLanguage !== queryLanguage) {
      i18n.changeLanguage(queryLanguage);
      const locale = getLocale(queryLanguage).toLowerCase();
      const country = getCountryFromLocale(locale);
      Cookies.set('language', getLanguageFromLocale(locale));
      Cookies.set('country', country);
    }
  }, [currentLanguage, i18n, queryLanguage]);

  useEffect(() => {
    unblockAccount(query.get('token'));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles['login-wrapper']}>
        <main className={styles['login-content']}>
          <SubsidiaryAppLogo />
          {isUnsupportedBrowser ? (
            <UnsupportedBrowser />
          ) : (
            <>
              {isLoading ? (
                <LoadingSpinner isLoading centered size="large" dataTestId="spinner" />
              ) : (
                <>
                  <div>
                    <img
                      src={error ? UnblockingUnSuccessful : UnblockingSuccessful}
                      className={styles.illustration}
                      alt=""
                    />
                  </div>
                  <p className={styles.title}>
                    {error ? t('MPL_ACCOUNT_UNLOCKED_HEADER_FAILED') : t('MPL_ACCOUNT_UNLOCKED_HEADER')}
                  </p>
                  <p className={styles.text}>
                    {error ? t('MPL_ACCOUNT_UNLOCKED_BODY_FAILED') : t('MPL_ACCOUNT_UNLOCKED_BODY')}
                  </p>
                  {!error && (
                    <Button
                      text={t('MPL_ACCOUNT_UNLOCKED_BUTTON_LOG_IN')}
                      className={styles['login-button']}
                      red
                      onClick={() => window.location.replace('/login')}
                      disabled={error}
                      dataTestId="mypages-login-submit"
                    />
                  )}
                </>
              )}
            </>
          )}
        </main>
      </div>
      <CookieInfo cookieInfoVisible={cookieInfoVisible} setCookieInfoVisible={setCookieInfoVisible} />
    </>
  );
}

export default UnblockPage;
