import { getErrorMessage } from 'common/errors/errorUtils';

const LAST_SUCCESSFUL_ENDPOINT_KEY = 'failoverClient.lastSuccessfulEndpoint';
const NOT_FOUND = 404;
const BAD_GATEWAY = 502;
const SERVICE_UNAVAILABLE = 503;
const GATEWAY_TIMEOUT = 504;

export const FAILOVER_STATUSES = [NOT_FOUND, BAD_GATEWAY, SERVICE_UNAVAILABLE, GATEWAY_TIMEOUT];

const defaultFailoverPolicy = (response, error = null) => error || FAILOVER_STATUSES.includes(response.status);

const saveEndpoint = endPoint => {
  localStorage.setItem(LAST_SUCCESSFUL_ENDPOINT_KEY, endPoint);
};

export const failover = instances => {
  const lastSuccessfulEndpoint = localStorage.getItem(LAST_SUCCESSFUL_ENDPOINT_KEY) || '';
  let currentIndex = instances.findIndex(instance => instance === lastSuccessfulEndpoint);
  if (currentIndex === -1) {
    currentIndex = 0;
    saveEndpoint(instances[currentIndex]);
  }

  const getCurrentInstance = () => instances[currentIndex];

  const switchInstance = () => {
    currentIndex = currentIndex + 1 > instances.length - 1 ? 0 : currentIndex + 1;
    saveEndpoint(instances[currentIndex]);
  };

  const withFailover = async (
    url,
    data,
    failoverPolicy = defaultFailoverPolicy,
    numberOfRetries = instances.length - 1
  ) => {
    let response;
    let error;
    try {
      response = await fetch(`${getCurrentInstance()}${url}`, data);
    } catch (e) {
      error = e;
    }
    if (failoverPolicy(response, error) && numberOfRetries > 0) {
      switchInstance();
      return withFailover(url, data, failoverPolicy, numberOfRetries - 1);
    }
    if (error) {
      const firefox = new RegExp(/NetworkError/gi);
      const chrome = new RegExp(/Failed to fetch/gi);
      const safari = new RegExp(/network error/gi);

      const ErrToString = error.toString();
      if (ErrToString.match(firefox) || ErrToString.match(chrome) || ErrToString.match(safari)) {
        throw new Error(getErrorMessage('NETWORK_ERROR'));
      }
      throw new Error(getErrorMessage());
    }
    return response;
  };
  return withFailover;
};
