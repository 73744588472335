import { useState } from 'react';
import { fetchWithFailover } from 'default-client/DefaultClient';

export const useUnblockAccount = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleError = () => {
    setIsLoading(false);
    setError(true);
  };

  const handleResponse = response => {
    if (response.status === 200) {
      setError(false);
    } else {
      setError(true);
    }
    setIsLoading(false);
  };

  const unblockAccount = token => {
    setIsLoading(true);
    const url = `/auth/unblock/${token}`;

    return fetchWithFailover(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
      },
    })
      .then(handleResponse)
      .catch(handleError);
  };

  return { unblockAccount, error, isLoading };
};
