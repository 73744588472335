import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'mypages-library';
import { useTranslation } from 'react-i18next';
import { t } from 'i18n/translate';

import styles from './CookieInfo.module.scss';

function CookieInfo({ cookieInfoVisible, setCookieInfoVisible }) {
  const { i18n } = useTranslation();
  if (!cookieInfoVisible) {
    return null;
  }

  const closeCookieBanner = () => {
    setCookieInfoVisible(false);
    localStorage.setItem('cookie-banner-state', 'cleared');
  };
  return (
    <section className={styles['cookie-banner']}>
      <div className={styles['cookie-banner-content']}>
        <p>{t('MPL_COOKIE_BANNER_TITLE')}</p>
        <p>
          {t('MPL_COOKIE_BANNER_READ_MORE')}&nbsp;
          <a
            href={`${i18n.language}/cookiepolicy.html`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles['cookie-info-link']}
          >
            {t('MPL_COOKIE_BANNER_USE_OF_COOKIES_LINK')}
          </a>
        </p>
      </div>
      <Icon icon="icon-close" className={styles['cookie-banner-close-icon']} onClick={closeCookieBanner} />
    </section>
  );
}

CookieInfo.propTypes = {
  cookieInfoVisible: PropTypes.bool.isRequired,
  setCookieInfoVisible: PropTypes.func.isRequired,
};

export default CookieInfo;
