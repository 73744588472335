import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner } from 'mypages-library';
import { Input } from 'components/Input/Input';
import { t } from 'i18n/translate';
import { useValidate } from 'common/hooks/useValidate';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';

import styles from './MultiFactorAuthentication.module.scss';

function ValidateToken({ setRequestView, email, mfaResponse }) {
  const TOKEN_REQUIRED_EXACT_LENGTH = 6;
  const [token, setToken] = useState('');
  const [isValidLength, setIsValidLength] = useState(false);
  const [validateResponse, setValidateResponse] = useState({});

  const { scrambledPhone, type, errorCode, maxRemainingMfaTokens, mfaTokenValiditySeconds } = mfaResponse;
  const timer = useRef(null);

  const handleMfaTokenTimeout = () => {
    timer.current = setTimeout(() => window.location.replace('/login'), mfaTokenValiditySeconds * 1000);
  };
  const { isLoading, validate } = useValidate(setValidateResponse);

  const handleEnterKey = event => {
    if (event.key === 'Enter' && isValidLength) {
      validate(token, email);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKey, { once: false });

    return () => document.removeEventListener('keydown', handleEnterKey);
  });

  useEffect(() => {
    if (typeof mfaTokenValiditySeconds === 'number') {
      handleMfaTokenTimeout();
    }
  }, [mfaTokenValiditySeconds]); // eslint-disable-line

  const tokenType = type?.toUpperCase();

  const setRequestViewAndClearTimeout = useCallback(
    view => {
      setRequestView(view);
      clearTimeout(timer.current);
    },
    [setRequestView]
  );

  useEffect(() => {
    const isLoggedIn = Boolean(validateResponse.accessToken);

    const noRemainingMfaTokens = validateResponse.maxRemainingMfaTokens === '0';

    const toManyMfaValidations =
      validateResponse.tooManyMfaValidations === 'true' || validateResponse.maxRemainingValidationAttempts === '0';

    const accountLocked =
      validateResponse.maxRemainingStepUpTokens === 0 && noRemainingMfaTokens && toManyMfaValidations;

    if (isLoggedIn) {
      setRequestViewAndClearTimeout('trust');
    } else if (accountLocked) {
      setRequestViewAndClearTimeout('userLocked');
    } else if (noRemainingMfaTokens && toManyMfaValidations) {
      setRequestViewAndClearTimeout('tooManyMfaTokensRequested');
    } else if (toManyMfaValidations) {
      setRequestViewAndClearTimeout('outOfValidationAttempts');
    }
  }, [validateResponse, setRequestView, setRequestViewAndClearTimeout]);

  const invalidToken = validateResponse.errorCode === 'INVALID_TOKEN';
  const lastAttempt = validateResponse.maxRemainingValidationAttempts === '1';
  return (
    <div data-testid="validateToken" className={styles['login-wrapper']}>
      <main className={styles['login-content']}>
        {isLoading && <LoadingSpinner isLoading centered size="large" />}
        <SubsidiaryAppLogo />
        {invalidToken && !lastAttempt && (
          <div id="submittedFaultyToken">
            <p className={styles.title}>{t('MPL_CODE_INCORRECT_TITLE')}</p>
            <p className={styles.text}>{t('MPL_CODE_INCORRECT_DESC')}</p>
          </div>
        )}
        {invalidToken && lastAttempt && (
          <div id="submittedFaultyTokenOneLeft">
            <p className={styles.title}>{t('MPL_CODE_INCORRECT_TITLE')}</p>
            <p className={styles.text}>{t('MPL_CODE_INCORRECT_DESC_ONE_MORE')}</p>
          </div>
        )}
        {!errorCode && !invalidToken && tokenType === 'PHONE' && (
          <p className={styles.title}>{t('MPL_CODE_PHONE_SENT_TO', { phoneNumber: scrambledPhone })}</p>
        )}
        {!errorCode && !invalidToken && tokenType === 'EMAIL' && (
          <p className={styles.title}>{t('MPL_CODE_EMAIL_SENT_TO', { email })}</p>
        )}

        {!errorCode && !invalidToken && <p className={styles.text}>{t(`MPL_ENTER_CODE_${tokenType}_DESCRIPTION`)}</p>}

        <Input
          type="text"
          placeholder={t(`MPL_ENTER_CODE_${tokenType}`)}
          onChange={(value, isValid) => {
            setToken(value.toUpperCase());
            setIsValidLength(isValid);
          }}
          focus
          value={token}
          className={styles['token-field']}
          dataTestId="validateTokenInput"
          icon="icon-close"
          minLength={TOKEN_REQUIRED_EXACT_LENGTH}
          maxLength={TOKEN_REQUIRED_EXACT_LENGTH}
          onIconClick={() => {
            setToken('');
            setIsValidLength(false);
          }}
          autoComplete="one-time-code"
        />
        {validateResponse.errorMessage && (
          <div className={styles['error-message']}>{t('MPL_CODE_INCORRECT_ERROR_MESSAGE')}</div>
        )}

        <Button
          text={t('MPL_SUBMIT_CODE')}
          className={styles['submit-button']}
          white
          disabled={!isValidLength}
          onClick={() => validate(token, email)}
          dataTestId="submit-validate"
        />

        {invalidToken && (
          <div>
            <p className={styles.title}>{t('MPL_CODE_INCORRECT_MORE_INFO')}</p>
          </div>
        )}
        <p
          id="noTokenReceived"
          data-testid="validateTokenRequestAgain"
          className={styles['missing-token-link']}
          onClick={() => {
            if (maxRemainingMfaTokens === 0) {
              setRequestViewAndClearTimeout('tooManyMfaTokensRequested');
            } else {
              setRequestViewAndClearTimeout('requestAgain');
            }
          }}
        >
          {invalidToken ? <>{t('MPL_VALIDATE_REQUEST_NEW_CODE')}</> : <>{t('MPL_VALIDATE_NO_CODE')}</>}
        </p>
      </main>
    </div>
  );
}

ValidateToken.propTypes = {
  setRequestView: PropTypes.func.isRequired,
  mfaResponse: PropTypes.shape({
    scrambledPhone: PropTypes.string,
    type: PropTypes.string,
    errorCode: PropTypes.string,
    maxRemainingMfaTokens: PropTypes.number,
    mfaTokenValiditySeconds: PropTypes.number,
  }),
  email: PropTypes.string.isRequired,
};

ValidateToken.defaultProps = {
  mfaResponse: {},
};
export default ValidateToken;
