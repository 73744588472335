import { useState } from 'react';
import { ga } from 'mypages-library';
import { fetchWithFailover } from 'default-client/DefaultClient';

export const useRequestToken = (setRequestView, setDataResponse) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const track = eventLabel => {
    ga.trackEvent({
      eventCategory: 'site login',
      eventAction: 'mfa request code',
      eventLabel,
    });
  };

  const handleError = e => {
    if (e.name === 'tooManyMfaTokensError') {
      setRequestView('tooManyMfaTokensRequested');
    } else if (e.name === 'tooManySmsSentError') {
      setRequestView('tooManySmsSent');
    } else if (e.name === 'wrongPhoneNumberError') {
      setRequestView('wrongPhoneNumber');
    } else {
      setError(e.message);
    }
  };

  const handleRequestData = data => {
    setRequestView('validateToken');
    setDataResponse({ ...data });
  };

  const mapError = response =>
    response.json().then(body => {
      if (response.status === 401 && body.tooManyMfaTokens) {
        const tooManyMfaTokensError = new Error('Too many mfa tokens sent.');
        tooManyMfaTokensError.name = 'tooManyMfaTokensError';
        throw tooManyMfaTokensError;
      } else if (response.status === 401) {
        const invalidUserNameOrPassword = new Error(
          'The email address or password you entered is incorrect. Please try again.'
        );
        invalidUserNameOrPassword.name = 'invalidUserNameOrPassword';
        throw invalidUserNameOrPassword;
      } else if (response.status === 400 && body?.errorCode === 'ACC_00008') {
        const tooManySmsSentError = new Error('Too many sms sent.');
        tooManySmsSentError.name = 'tooManySmsSentError';
        throw tooManySmsSentError;
      } else if (response.status === 400 && body?.errorCode === 'ACC_00003') {
        const wrongPhoneNumberError = new Error('Wrong phone number.');
        wrongPhoneNumberError.name = 'wrongPhoneNumberError';
        throw wrongPhoneNumberError;
      } else if (response.status !== 200) {
        const unknownError = new Error('Something went wrong, try again later!');
        unknownError.name = 'unknownError';
        throw unknownError;
      }
    });

  const handleRequestResponse = response => {
    setIsLoading(false);
    if (response.status === 200) {
      return response.json();
    }
    return mapError(response);
  };

  const requestToken = type => {
    setIsLoading(true);

    let url = '/auth/mfa';

    if (type === 'email') {
      url += '?type=email';
      track('email');
    } else {
      track('sms');
    }

    return fetchWithFailover(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json',
        APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
      },
    })
      .then(handleRequestResponse)
      .then(handleRequestData)
      .catch(handleError);
  };
  return { isLoading, error, requestToken };
};
