export const siteLanguages = ['dk', 'uk', 'fr', 'frbe', 'nl', 'nlbe', 'no', 'fi', 'se', 'de'];
export const getLocale = (siteLanguage: string | undefined) => {
  switch (siteLanguage) {
    case 'dk':
      return 'da-DK';
    case 'de':
      return 'de-DE';
    case 'uk':
      return 'en-GB';
    case 'fr':
      return 'fr-FR';
    case 'frbe':
      return 'fr-BE';
    case 'nlbe':
      return 'nl-BE';
    case 'nl':
      return 'nl-NL';
    case 'no':
      return 'no';
    case 'fi':
      return 'fi-FI';
    case 'se':
      return 'sv-SE';
    default:
      return 'sv-SE';
  }
};
export const getCountryFromLocale = (locale: string) => {
  const country = locale.split('-')[1];
  return country !== undefined ? country : locale.toUpperCase();
};

export const getLanguageFromLocale = (locale: string) => {
  const language = locale.split('-')[0];
  return language !== undefined ? language : locale.toLowerCase();
};
