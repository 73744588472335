import React from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner } from 'mypages-library';
import { t } from 'i18n/translate';

import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';

import styles from 'components/Login/Login.module.scss';

function TrustDevice({ useTrust }) {
  const { trust, error, isLoading } = useTrust();

  return (
    <div className={styles['login-wrapper']}>
      <main className={styles['login-content']}>
        {isLoading && <LoadingSpinner isLoading centered size="large" />}
        <SubsidiaryAppLogo />
        <p className={styles['download-title']} data-testid="title">
          {t('MPL_REMEMBER_DEVICE')}
        </p>
        <p className={styles['sign-up-text']}>{t('MPL_REMEMBER_DEVICE_DESCR')}</p>
        {error && <div className={styles['error-message']}>{error}</div>}
        <Button
          text={t('MPL_REMEMBER_DEVICE_BUTTON')}
          className={styles['login-button']}
          white
          onClick={() => trust(true)}
          dataTestId="rememberDeviceButton"
          disabled={isLoading}
        />
        <Button
          secondary
          text={t('MPL_MAYBE_LATER')}
          className={styles['login-button']}
          white
          onClick={() => trust(false)}
          dataTestId="rememberDeviceLaterButton"
          disabled={isLoading}
        />
      </main>
    </div>
  );
}

TrustDevice.propTypes = {
  useTrust: PropTypes.func.isRequired,
};

export default TrustDevice;
