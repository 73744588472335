export const browserIsIE = () => {
  const sAgent = window.navigator.userAgent;
  const Idx = sAgent.indexOf('MSIE');

  return Idx > 0 || !!window.navigator.userAgent.match(/Trident\/7\./);
};

export const browserIsIE11 = () => Object.hasOwnProperty.call(window, 'ActiveXObject') && !window.ActiveXObject;

// eslint-disable-next-line consistent-return
export const browserIsOldEdge = () => {
  const UAString = navigator && navigator.userAgent;
  const edgeRegex = UAString.match(/Edge\/\d+/);
  if (edgeRegex) {
    const versionNumber = edgeRegex[0].split('/')[1];
    // Mypages do not suport EdgeHtml any longer, only the new Edge chromium based
    return versionNumber <= 18;
  }
};

export const browserIsUnsupported = () => browserIsIE() || browserIsOldEdge();
