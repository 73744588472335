import React from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner } from 'mypages-library';
import { useRequestToken } from 'common/hooks/useRequestToken';
import { t } from 'i18n/translate';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';

import styles from './MultiFactorAuthentication.module.scss';

function RequestSMS({ setRequestView, setDataResponse }) {
  const { isLoading, error, requestToken } = useRequestToken(setRequestView, setDataResponse);
  return (
    <div data-testid="requestSMS" className={styles['login-wrapper']}>
      <main className={styles['login-content']}>
        {isLoading && <LoadingSpinner isLoading centered size="large" />}
        <SubsidiaryAppLogo />
        <p className={styles.title}>{t('MPL_EXTRA_SECURITY_TITLE')} </p>
        <p className={styles.text}>{t('MPL_EXTRA_SECURITY_DESCRIPTION')}</p>
        {error && <div className={styles['error-message']}>{error}</div>}
        <Button
          text={t('MPL_EXTRA_SECURITY_REQUEST_SMS_CODE')}
          className={styles['submit-button']}
          white
          onClick={() => requestToken()}
          dataTestId="sendSMSButton"
        />
        <Button
          flat
          text={t('MPL_GO_BACK')}
          className={styles['submit-button']}
          white
          onClick={() => window.location.replace('/login')}
        />
      </main>
    </div>
  );
}

RequestSMS.propTypes = {
  setRequestView: PropTypes.func.isRequired,
  setDataResponse: PropTypes.func.isRequired,
};
export default RequestSMS;
