import React from 'react';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import styles from './WebAccountCreated.module.scss';

type GenericWebAccountStatusProps = {
  header: string;
  body: string;
  image?: string;
  children?: React.ReactNode;
  altText?: string;
};

const GenericWebAccountStatus = ({ header, body, image, children, altText }: GenericWebAccountStatusProps) => (
  <div className={styles.centered}>
    <div className={styles.container}>
      <SubsidiaryAppLogo />
      <div className={styles.infoContainer}>
        <h1 className={styles.infoHeader}>{header}</h1>
        <p className={styles.infoText}>{body}</p>
      </div>
      {image && (
        <div className={styles.imageContainer}>
          <img src={image} className={styles.webAccountImage} alt={altText} />
        </div>
      )}
      {children}
    </div>
  </div>
);

GenericWebAccountStatus.defaultProps = {
  children: null,
  altText: null,
  image: null,
};

export default GenericWebAccountStatus;
