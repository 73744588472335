import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { apiEndpoint, GoogleAnalytics } from 'mypages-library';
import * as Sentry from '@sentry/react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import UnblockPage from 'components/UnblockPage/UnblockPage';
import SingleSignOn from 'components/SingleSignOn/SingleSignOn';
import CreateAccount from 'components/CreateAccount/CreateAccount';
import Redirect from 'components/Redirect/Redirect';
import DeepLink from 'components/DeepLink/DeepLink';
import App from './App';

import './i18n/i18n';
import './index.scss';

ReactDOM.render(<GoogleAnalytics />, document.getElementById('google'));

const Routing = () => {
  const environment = apiEndpoint.getCurrentEnvironment(window.location?.hostname);

  Sentry.init({
    dsn: 'https://0f21e55a0ac0474f9992bc681dffc5ad@o250084.ingest.sentry.io/4503959539089408',
    normalizeDepth: 10,
    environment,
    enabled: environment === 'galactica' || environment === 'production',
  });

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login/createaccount" component={CreateAccount} />
        <Route exact path="/login/redirect" component={Redirect} />
        <Route exact path="/login/deeplink" component={DeepLink} />
        <Route exact path="/login/sso" component={SingleSignOn} />
        <Route exact path="/login/unblock" component={UnblockPage} />
        <Route exact path="/login" component={App} />
      </Switch>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);
