import React from 'react';
import { Button } from 'mypages-library';
import { useHistory } from 'react-router-dom';
import { t } from 'i18n/translate';
import GenericWebAccountStatus from 'components/WebAccountCreated/GenericWebAccountStatus';

const WebAccountInvalidInvite = () => {
  const navigate = useHistory();
  const goToLogin = () => {
    navigate.push('/login');
  };

  return (
    <GenericWebAccountStatus
      header={t('MPL_CREATE_ACCOUNT_ERROR_INVITATION_INVALID_TITLE')}
      body={t('MPL_CREATE_ACCOUNT_ERROR_INVITATION_INVALID_DESC')}
    >
      <Button primary text={t('MPL_CREATE_ACCOUNT_VERIFY_LOG_IN')} onClick={goToLogin} />
    </GenericWebAccountStatus>
  );
};

export default WebAccountInvalidInvite;
