import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'mypages-library';
import Cookies from 'js-cookie';
import { initStates, useInitialize } from 'common/hooks/useInitialize';
import { useAuthenticate } from 'common/hooks/useAuthenticate';
import { t } from 'i18n/translate';
import { getQueryParam } from 'common/urlParser';
import styles from './SingleSignOn.module.scss';

const SingleSignOn = () => {
  const { state } = useInitialize();
  const [vid] = useState(getQueryParam('tempVid', window.location.search));
  const [userEmail] = useState(getQueryParam('user', window.location.search));
  const [cookiesSet, setCookiesSet] = useState(false);
  const { authenticateSingleSignOn, errorBody } = useAuthenticate();

  useEffect(() => {
    if (cookiesSet) {
      authenticateSingleSignOn(userEmail, vid);
    }
  }, [vid, userEmail, state, cookiesSet]);

  useEffect(() => {
    if (state === initStates.DONE && !cookiesSet) {
      Cookies.set('vid', vid, {
        path: '/',
        domain: '.verisure.com',
        secure: true,
      });
      Cookies.set('username', userEmail, {
        path: '/',
        domain: '.verisure.com',
        secure: true,
      });
      sessionStorage.setItem('runningInApp', true);
      setCookiesSet(true);
    }
  }, [vid, userEmail, state]);

  return (
    <div className={styles.elpWrapper}>
      <LoadingSpinner isLoading centered size="large" datatestid="spinner" />
      {errorBody && <div className={styles['error-message']}>{t('MPL_ERROR_SSO')}</div>}
    </div>
  );
};

export default SingleSignOn;
