import React from 'react';
import classNames from 'classnames';
import { Icon } from 'mypages-library';
import { t } from 'i18n/translate';
import styles from './InputValidation.module.scss';

type Props = {
  inputToValidate: string;
  children: React.ReactNode;
  requirements: Requirement[];
  errorList: Requirement[];
};

type Requirement = {
  title: string;
  validation: boolean;
  errorText: string;
};

const InputValidation = ({ children, inputToValidate, requirements, errorList }: Props) => (
  <div className={styles.validationContainer}>
    {children}
    {inputToValidate?.length > 0 && (
      <div className={styles.errorContainer}>
        {!errorList?.every(err => err.validation) && (
          <Icon icon="icon-alert" size="small" className={styles.errorIcon} />
        )}
        <ul className={styles.validationError}>
          {errorList?.map(err => (
            <li key={err.errorText}>{t(err.errorText)}</li>
          ))}
        </ul>
      </div>
    )}
    <div className={styles.requirementsContainer}>
      <p>{t('MPL_PASSWORD_VALIDATION_PASSWORD_STRENGTH')}</p>
      <ul className={styles.requirementsList}>
        {requirements?.map(req => (
          <li key={req.title} className={classNames(styles.validationRequirement, req.validation && styles.fullfilled)}>
            {t(req.title)}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default InputValidation;
