import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ga } from 'mypages-library';
import { fetchWithFailover } from 'default-client/DefaultClient';
import { navigateTo } from 'common/navigationUtil';
import { getErrorMessage } from 'common/errors/errorUtils';

export const useTrust = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useTranslation();
  const getLanguage = () => i18n.language || 'se';

  const track = eventLabel => {
    ga.trackEvent({
      eventCategory: 'site login',
      eventAction: 'add trusted device',
      eventLabel,
    });
  };

  const handleTrustResponse = async response => {
    const reply = await response.json();
    if (response.status !== 200) {
      throw new Error(getErrorMessage());
    }
    return reply;
  };

  const trust = async accepted => {
    setIsLoading(true);
    if (accepted) {
      try {
        const response = await fetchWithFailover('/auth/trust', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'content-type': 'application/json',
            APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
          },
        });
        await handleTrustResponse(response);
        track('yes');
        navigateTo(getLanguage());
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    } else {
      track('no');
      navigateTo(getLanguage());
    }
  };

  return { isLoading, error, trust };
};
