import React, { useRef } from 'react';
import classNames from 'classnames';
import { Icon, useClickOutsideElement } from 'mypages-library';
import DropDownBox from '../DropDownBox/DropDownBox';
import styles from './LanguageSelectButton.module.scss';

interface MenuController {
  menuExpanded: boolean;
  setMenuExpanded: (expanded: boolean) => void;
}

interface LanguageSelectButtonProps {
  label?: string;
  dataTestId: string;
  children?: React.ReactNode;
  className?: string;
  menuController: MenuController;
}

const LanguageSelectButton: React.FC<LanguageSelectButtonProps> = ({
  label,
  dataTestId,
  children,
  className,
  menuController,
}) => {
  const { menuExpanded, setMenuExpanded } = menuController;
  const refNode = useRef(null);
  useClickOutsideElement(refNode, () => setMenuExpanded(false));

  const buttonArrow = menuExpanded ? 'icon-arrow-up' : 'icon-arrow-down';
  return (
    <div className={classNames(styles.dropDownButton, menuExpanded && styles.expanded, className)} ref={refNode}>
      <button
        type="button"
        onClick={() => setMenuExpanded(!menuExpanded)}
        className={classNames(!children && styles.disabled)}
        id={dataTestId}
        data-testid={dataTestId}
      >
        {label && <span className={styles.buttonText}>{label}</span>}
        {children && <Icon icon={buttonArrow} className={styles.buttonArrow} size="small" />}
      </button>
      <DropDownBox expanded={menuExpanded}>{children}</DropDownBox>
    </div>
  );
};

export default LanguageSelectButton;
