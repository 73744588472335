/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { t } from 'i18n/translate';

type AgreementData = {
  _path: string;
  content: { html: string };
};

const useGetTermsAndConditions = () => {
  const [terms, setTerms] = useState<AgreementData[]>([]);
  const [error, setError] = useState<string>('');

  const formattedLocale = (locale: string) => {
    if (locale === 'no_NO') {
      return 'no/nb';
    }

    return `${locale.slice(3).toLowerCase()}/${locale.slice(0, 2)}`;
  };

  const fetchTerms = async (url: string) => {
    try {
      const fetchedTerms = await fetch(`${url}/graphql/execute.json/verisure/webaccountAgreements`);
      const {
        data: {
          agreementList: { items },
        },
      } = await fetchedTerms.json();

      setTerms(items);
    } catch (e) {
      setError(t('MPL_ETC_ERROR_OCCURED_TRY_AGAIN'));
    }
  };

  useEffect(() => {
    const getUrl = async () => {
      try {
        const urlData = await fetch('/login/config');
        const { apiUrl } = await urlData.json();
        await fetchTerms(apiUrl);
      } catch (e) {
        setError(t('MPL_ETC_ERROR_OCCURED_TRY_AGAIN'));
      }
    };
    getUrl();
  }, []);

  const getAgreementByLocale = (locale: string) =>
    terms.find((agreement: AgreementData) => agreement._path.includes(formattedLocale(locale)))?.content?.html ?? null;

  return { getAgreementByLocale, error };
};

export default useGetTermsAndConditions;
