import React from 'react';
import i18n from './i18n';
import styles from './translate.module.scss';

export const t = (key, ...args) => {
  if (typeof key === 'undefined') {
    return i18n.t(key, ...args);
  }
  const cKey = `${key}-${JSON.stringify(args)}`;
  const value = i18n.t(key, ...args);
  if (value.indexOf('\n') === -1) {
    return value;
  }
  const translation = [
    <span key={cKey} className={styles.wrap}>
      {value}
    </span>,
  ];
  translation.toString = () => value;
  return translation;
};
