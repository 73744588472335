import React, { Children, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './DropDownBox.module.scss';

interface DropDownBoxProps {
  expanded: boolean;
  children?: ReactNode;
  className?: string;
}

const DropDownBox: React.FC<DropDownBoxProps> = ({ expanded, children, className }) => {
  // eslint-disable-next-line react/no-array-index-key
  const getItems = (ch: ReactNode) => Children.map(ch, (child, index) => <li key={index}>{child}</li>);

  const childWithProp = children ? getItems(children) : null;

  return (
    <div className={classNames(styles['drop-down-box'], expanded && styles.expanded, className)}>
      <ul>{childWithProp}</ul>
    </div>
  );
};

export default DropDownBox;
