import React from 'react';
import { t } from 'i18n/translate';
import chromeIcon from 'images/browsericons/IC_chrome_48x48.png';
import firefoxIcon from 'images/browsericons/IC_Firefox_48x48.png';
import safariIcon from 'images/browsericons/IC_safari_48x48.png';
import edgeIcon from 'images/browsericons/IC_Edge_48x48.png';
import warningIcon from 'images/misc/warning_40x40.png';
import { browserIsIE11, browserIsOldEdge } from './unsupportedBrowserUtils';
import styles from './UnsupportedBrowser.module.scss';

function UnsupportedBrowser() {
  const isIE11 = browserIsIE11();
  const isOldEdge = browserIsOldEdge();

  return (
    <>
      {isIE11 || isOldEdge ? (
        <>
          <div className={styles['ie11-browser-notice-overlay']} />
          <div id="ie11Banner" className={styles['ie11-browser-notice']}>
            <p className={styles['ie11-browser-notice-title']}>
              {isIE11 && t('MPL_UNSUPPORTED_BROWSER_NOTICE_TITLE')}
              {isOldEdge && t('MPL_UPGRADE_BROWSER_NOTICE_TITLE')}
            </p>
            <p className={styles['ie11-browser-notice-content']}>
              {isIE11 && t('MPL_UNSUPPORTED_BROWSER_NOTICE_CONTENT')}
              {isOldEdge && t('MPL_UPGRADE_BROWSER_NOTICE_CONTENT')}
            </p>
            <div className={styles['ie11-browser-notice-icons']}>
              {isIE11 && (
                <>
                  <a href="https://www.google.com/chrome" target="_blank" rel="noopener noreferrer">
                    <img src={chromeIcon} alt="Chrome" title="Chrome" />
                    Chrome
                  </a>
                  <a href="https://www.mozilla.org/firefox" target="_blank" rel="noopener noreferrer">
                    <img src={firefoxIcon} alt="Firefox" title="Firefox" />
                    Firefox
                  </a>
                  <a href="https://www.apple.com/safari" target="_blank" rel="noopener noreferrer">
                    <img src={safariIcon} alt="Safari" title="Safari" />
                    Safari
                  </a>
                </>
              )}
              <a href="https://www.microsoft.com/edge" target="_blank" rel="noopener noreferrer">
                <img src={edgeIcon} alt="Microsoft Edge" title="Microsoft Edge" />
                Edge
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles['ie-browser-notice-overlay']} />
          <div id="ieBanner" className={styles['ie-browser-notice']}>
            <div className={styles['ie-browser-notice--box']}>
              <p className={styles['ie-browser-notice--text']}>
                <img src={warningIcon} alt="Warning" title="Warning" className={styles['ie-browser-notice--image']} />
                {t('MPL_OLDER_UNSUPPORTED_BROWSER_NOTICE')}&nbsp;
                <a href="https://www.whatsmybrowser.org/" target="_blank" rel="noopener noreferrer">
                  {t('MPL_WHAT_BROWSER_AM_I_USING')}
                </a>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UnsupportedBrowser;
