import * as Sentry from '@sentry/react';

const version = process.env.REACT_APP_VERSION;
const name = process.env.REACT_APP_NAME;
const fixedTags = { version, name };

export const simpleSentryLog = (errorLabel = 'generic', extra = {}, tagData = {}) => {
  const composeError = new Error(errorLabel);
  Sentry.withScope(scope => {
    scope.setExtras({ ...extra });
    scope.setTags({ ...fixedTags, ...tagData });
    Sentry.captureException(composeError);
  });
};
