import { fetchWithFailover } from 'default-client/DefaultClient';
import { t } from 'i18n/translate';

const getHeaders = () => ({
  APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
});

export const getDealerSettings = async (locale: string) => {
  try {
    const headers = getHeaders();
    const url = `/dealersettings?locale=${locale}`;
    const response = await fetchWithFailover(url, {
      method: 'GET',
      headers,
    });

    if (!response?.ok) {
      return { privacyPolicy: null, errorText: t('MPL_PRIVACY_POLICY_ERROR') };
    }

    const { privacyPolicy } = await response.json();
    return { privacyPolicy, errorText: null };
  } catch (e) {
    return { privacyPolicy: null, errorText: t('MPL_PRIVACY_POLICY_ERROR') };
  }
};
