import { getQueryParam } from 'common/urlParser';

const encodedOriginUrl = window.btoa(window.location.href);
const language = getQueryParam('lang', window.location.search) || 'se';

const getBaseUrl = () => {
  const { hostname } = window.location;
  let baseUrl = '';
  switch (hostname) {
    case 'local-mypages.verisure.com':
    case 'mypages.local.verisure.com':
    case 'local.verisure.com':
    case 'localhost':
      baseUrl = 'https://local-mypages.verisure.com';
      break;
    case 'galactica-oauth.dev.verisure.com':
      baseUrl = 'https://galactica-mypages.dev.verisure.com';
      break;
    case 'oauth.sys.verisure.com':
      baseUrl = 'https://mypages.sys.verisure.com';
      break;
    case 'oauth.verisure.com':
      baseUrl = 'https://mypages.verisure.com';
      break;
    default:
      baseUrl = window.location.href;
  }
  return baseUrl;
};

export const getWebshopForgotPasswordUrl = () =>
  `${getBaseUrl()}/${language}/forgotpassword.html?requestOrigin=${encodedOriginUrl}`;

export const getWebshopCreateAccountUrl = () =>
  `${getBaseUrl()}/${language}/createaccount.html?requestOrigin=${encodedOriginUrl}`;
