import { LoadingSpinner } from 'mypages-library';
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ResumeStates, useResumeSession } from 'common/hooks/useResumeSession';
import { navigateTo } from 'common/navigationUtil';

interface DeeplLinkProps {}
const DeepLink: React.FC<DeeplLinkProps> = () => {
  const location = useLocation();
  const { resumeState } = useResumeSession();
  const navigate = useHistory();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (resumeState === ResumeStates.FAIL) {
      const queryParams = new URLSearchParams(location.search);
      const giid = queryParams.get('installation');
      if (!giid) {
        queryParams.set('deeplink', 'true');
      }
      navigate.push(`/login?${queryParams.toString()}`);
    } else if (resumeState === ResumeStates.SUCCESS) {
      navigateTo(i18n.language);
    }
  }, [resumeState]);

  return (
    <div>
      <LoadingSpinner isLoading centered size="large" dataTestId="spinner" />
    </div>
  );
};

export default DeepLink;
