import { getQueryParam } from 'common/urlParser';

export const mapLang = language => {
  if (!language) {
    return null;
  }
  const supportedLanguages = ['dk', 'de', 'uk', 'fr', 'frbe', 'nlbe', 'nl', 'no', 'fi', 'se'];
  return supportedLanguages.includes(language) ? language : 'se';
};
const getLangFromQuery = {
  name: 'getLangFromQuery',

  lookup() {
    return mapLang(getQueryParam('lang'));
  },
};

export default getLangFromQuery;
