/* eslint-disable no-console */
import { client, apiEndpoint, gqlSentryLog, shouldLogError } from 'mypages-library';
import { simpleSentryLog } from 'common/utilities/simpleSentryLog';

const onLogout = () => {
  // Not sure how to handle this
  simpleSentryLog('Client logout triggered');
};

export const debugErrorMessage = error => {
  console.log('GQL login client: ', error);
  if (error.graphQLErrors?.length > 0) {
    const { log, errorMsg } = shouldLogError(error.graphQLErrors[0]);
    console.log(`Error: ${errorMsg}, Sentry logged: ${log}`);
  }
};

export const errorLogger = e => {
  if (!apiEndpoint.isInProduction) {
    debugErrorMessage(e);
  }
  gqlSentryLog(e);
};

const localDev = null; // ['https://local.verisure.com:8081', 'https://local.verisure.com:8081'];

export default client(errorLogger, localDev, onLogout);
