const errorCodes = [
  'AUT_00001',
  'AUT_00004',
  'AUT_00008',
  'NETWORK_ERROR',
  'INVALID_TOKEN',
  'AUT_00021',
  'urn:verisure:webaccount/web-account-already-exists',
];

export default errorCodes.reduce(
  (obj, errorCode) => ({
    ...obj,
    [errorCode]: errorCode,
  }),
  {}
);
