import { apiEndpoint } from 'mypages-library';
import { failover } from './failover';

let instances;
const apiHostName = apiEndpoint.getHostName();
const apiEndPointsMap = apiEndpoint.apiEndpoint(apiHostName);
if (apiEndpoint.getCurrentEnvironment(apiHostName) === 'local') {
  instances = ['http://localhost:4000', 'http://localhost:4000'];
} else {
  instances = [apiEndPointsMap.primary, apiEndPointsMap.failover];
}

const withFailover = failover(instances);
export const fetchWithFailover = (url, data) => withFailover(url, data);
