import Cookies from 'js-cookie';
// eslint-disable-next-line consistent-return
export const mapOldToNew = (country, language) => {
  // da(DK) de(DE) en(GB) fr(FR) fr(BE) nl(BE) nl(NL) no(NO) fi(FI) sv(SE)
  if (!country || !language) {
    return '';
  }
  switch (country) {
    case 'DK':
      return 'dk';
    case 'DE':
      return 'de';
    case 'GB':
      return 'uk';
    case 'FR':
      return 'fr';
    case 'BE':
      if (language === 'fr') return 'frbe';
      if (language === 'nl') return 'nlbe';
      break;
    case 'NL':
      return 'nl';
    case 'NO':
      return 'no';
    case 'FI':
      return 'fi';
    case 'SE':
      return 'se';
    default:
      return '';
  }
};

export default {
  name: 'inheritFromMGW',

  lookup() {
    return mapOldToNew(Cookies.get('country'), Cookies.get('language'));
  },
};
