import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getDealerSettingsBySiteLanguage } from 'common/dealerSettings';

const subsidiaries = {
  AlertAlarm: { forceLanguage: 'se' },
};

const getSubsidiary = query => {
  const querySub = query.get('subsidiary');
  const subsidiaryMatch = Object.keys(subsidiaries).find(key => key.toLowerCase() === querySub?.toLowerCase());
  const localStorageSub = localStorage.getItem('alternateSubsidiary');
  const localStorageMatch = Object.keys(subsidiaries).find(key => key.toLowerCase() === localStorageSub?.toLowerCase());
  return subsidiaryMatch || localStorageMatch;
};

export const useAlternateSubsidiary = () => {
  // Parse ?subsidiary=XYZ in location
  const query = new URLSearchParams(window.location.search.substring(1));
  const [subsidiary] = useState(getSubsidiary(query));
  const [subsidiaryLanguage, setSubsidiaryLanguage] = useState(null);

  const { i18n } = useTranslation();
  useEffect(() => {
    if (subsidiaries[subsidiary]) {
      localStorage.setItem('alternateSubsidiary', subsidiary);
      const { forceLanguage } = subsidiaries[subsidiary];
      if (forceLanguage) {
        setSubsidiaryLanguage(forceLanguage);
        if (i18n.language !== forceLanguage) {
          i18n.changeLanguage(forceLanguage);
        }
      }
    }
  }, [subsidiary, i18n]);

  const getPhoneNumber = useCallback(() => {
    const language = subsidiaryLanguage || i18n.language || 'se';
    const phoneNumbers = getDealerSettingsBySiteLanguage(language);

    if (subsidiary === 'AlertAlarm') {
      return phoneNumbers.phone_alert_alarm;
    }
    return phoneNumbers.phone;
  }, [subsidiary, i18n]);

  return { subsidiary, subsidiaryLanguage, getPhoneNumber };
};
