import { sendLog } from 'mypages-library';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

export const getCaptchaLang = (siteLanguage: string | undefined) => {
  switch (siteLanguage) {
    case 'dk':
      return 'da';
    case 'de':
      return 'de';
    case 'uk':
      return 'en';
    case 'fr':
      return 'fr';
    case 'frbe':
      return 'fr';
    case 'nlbe':
      return 'nl';
    case 'nl':
      return 'nl';
    case 'no':
      return 'no';
    case 'fi':
      return 'fi';
    case 'se':
      return 'sv';
    default:
      return 'sv';
  }
};

interface ReCaptchaProps {
  onChange: (token: string | null) => void;
}

export const ReCaptcha: React.FC<ReCaptchaProps> = ({ onChange }) => {
  const [key, setKey] = useState<string | null>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const getKey = async () => {
      try {
        const urlData = await fetch('/login/config');
        const { reCaptchaSiteKey } = await urlData.json();
        setKey(reCaptchaSiteKey);
      } catch (e) {
        sendLog('RECAPTCHA', e);
      }
    };

    getKey();
  }, []);

  if (!key) {
    return null;
  }

  return <ReCAPTCHA hl={getCaptchaLang(i18n.language)} sitekey={key} onChange={onChange} />;
};
