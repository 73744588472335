const MIN_LENGTH = 8;

export const getPasswordRequirements = (password: string) => [
  {
    title: 'MPL_PASSWORD_VALIDATION_ONE_UPPERCASE',
    validation: /[A-ZÅÄÖÆØ]/.test(password),
    errorText: 'MPL_PASSWORD_VALIDATION_UPPERCASE_ERROR',
  },
  {
    title: 'MPL_PASSWORD_VALIDATION_ONE_LOWERCASE',
    validation: /[a-zåäöæø]/.test(password),
    errorText: 'MPL_PASSWORD_VALIDATION_LOWERCASE_ERROR',
  },
  {
    title: 'MPL_PASSWORD_VALIDATION_ONE_NUMBER',
    validation: /\d/.test(password),
    errorText: 'MPL_PASSWORD_VALIDATION_NUMBER_ERROR',
  },
  {
    title: 'MPL_PASSWORD_VALIDATION_EIGHT_CHARS',
    validation: password.length >= MIN_LENGTH,
    errorText: 'MPL_PASSWORD_VALIDATION_MIN_LENGTH_ERROR',
  },
];
export const getPasswordErrorList = (password: string) => [
  ...getPasswordRequirements(password),
  {
    title: '',
    validation: /^[a-zåäöæøA-ZÅÄÖÆØ0-9_\-,.!#¤%&/()=?@£${[\]}+*;:<>|]*$/.test(password),
    errorText: 'MPL_PASSWORD_VALIDATION_FORBIDDEN_CHARACTERS_ERROR',
  },
];

export const isPasswordValid = (password: string) => getPasswordErrorList(password).every(req => req.validation);
export const passwordAndRepeatMatch = (password: string, repeatPassword: string) => password === repeatPassword;
export const getCurrentPasswordErrors = (password: string) =>
  getPasswordErrorList(password).filter(err => !err.validation);
