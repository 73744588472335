export const getDealerSettingsBySiteLanguage = (siteLanguage: string) => {
  switch (siteLanguage) {
    case 'dk':
      return {
        phone: '70 24 73 65',
        phone_international: '+45 70 24 7 365',
      };
    case 'de':
      return {
        phone: '0800 – 01 02 03 2',
        phone_international: '',
      };
    case 'uk':
      return {
        phone: '+44-333-2009000',
        phone_international: '+443332009000',
      };
    case 'fr':
      return {
        phone: '09 74 500 604',
        phone_international: '+33974500604',
      };
    case 'frbe':
    case 'nlbe':
      return {
        phone: '078 / 365 7 24',
        phone_international: '+32 2 263 70 00',
      };
    case 'nl':
      return {
        phone: '088 - 088 8 999',
        phone_international: '+32 2 263 70 00',
      };
    case 'no':
      return {
        phone: '21 49 44 44',
        phone_international: '+47 2368 8880',
      };
    case 'fi':
      return {
        phone: '010 217 9000',
        phone_international: '+358 10 217 9000',
      };
    case 'se':
      return {
        phone: '0771-22 50 00',
        phone_international: '+46 771-22 50 00',
        phone_alert_alarm: '0771-76 03 03',
        phone_alert_alarm_international: '+46 771-76 03 03',
      };
    default:
      return {
        phone: '020-72 43 65',
        phone_international: '+46 13 31 77 00',
      };
  }
};
