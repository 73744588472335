import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { appConstants } from 'mypages-library';
import { countries, Country } from 'utils/countries';
import PhoneSelectButton from 'components/common/PhoneSelectButton/PhoneSelectButton';
import { t } from 'i18n/translate';
import { Input } from 'components/Input/Input';
import styles from './InternationalPhonePicker.module.scss';

interface CountryDropdownPickerProps {
  phoneNumber: string;
  setPhoneNumber: (dialCode: string) => void;
  presetLanguage: string;
  phoneError: boolean;
}

const InternationalPhonePicker: React.FC<CountryDropdownPickerProps> = ({
  presetLanguage,
  phoneNumber,
  setPhoneNumber,
  phoneError,
}) => {
  const findPreselectedCountry = countries.find(country => country.code.split(',').includes(presetLanguage)) || null;
  const phoneErrorMsg = phoneError ? t('MPL_INVALID_PHONE_NUMBER') : '';
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(findPreselectedCountry);
  const { PHONE_MAX_LENGTH, PHONE_MIN_LENGTH } = appConstants;
  const sortedCountries = countries.sort((a, b) => {
    if (a.preferred && !b.preferred) return -1;
    if (!a.preferred && b.preferred) return 1;
    return 0;
  });

  const handleCountryChange = (country: Country) => {
    setSelectedCountry(country);
    setMenuExpanded(false);
  };

  const buttonRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});

  useEffect(() => {
    if (selectedCountry) {
      const selectedButton = buttonRefs.current[`${selectedCountry.dialCode}-${selectedCountry.code}`];

      if (selectedButton) {
        selectedButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedCountry, menuExpanded]);

  let hasInsertedSeparator = false;

  return (
    <div className={styles.inputWrapper}>
      <PhoneSelectButton
        label={selectedCountry ? `${selectedCountry.flag} ${selectedCountry.dialCode}` : ''}
        menuController={{ menuExpanded, setMenuExpanded }}
      >
        {sortedCountries.map((country: Country) => {
          const isNonPreferred = !country.preferred;
          const shouldInsertSeparator = isNonPreferred && !hasInsertedSeparator;
          if (shouldInsertSeparator) {
            hasInsertedSeparator = true;
          }

          return (
            <React.Fragment key={`${country.code}-${country.dialCode}`}>
              {shouldInsertSeparator && <div className={styles.separator} />}
              <button
                ref={el => (buttonRefs.current[`${country.dialCode}-${country.code}`] = el)}
                onClick={() => handleCountryChange(country)}
                value={country.dialCode}
                type="button"
                tabIndex={-1}
              >
                <div className={styles.countryContainer}>
                  <div
                    className={classNames(
                      country.code === selectedCountry?.code &&
                        country.dialCode === selectedCountry?.dialCode &&
                        styles.countrySelected
                    )}
                  >
                    {country.flag} {country.name} {country.dialCode}
                  </div>
                  <div className={styles.countrySelected}>
                    {country.code === selectedCountry?.code && country.dialCode === selectedCountry?.dialCode && '✓'}
                  </div>
                </div>
              </button>
            </React.Fragment>
          );
        })}
      </PhoneSelectButton>

      <div className={styles.inputField}>
        <Input
          placeholder={t('MPL_CREATE_ACCOUNT_MOBILE_PHONE')}
          value={phoneNumber}
          dataTestId="PhoneNumberInput"
          maxLength={PHONE_MAX_LENGTH}
          minLength={PHONE_MIN_LENGTH}
          type="tel"
          onChange={value => setPhoneNumber(value.replace(/\D/g, ''))}
          hasError={phoneError}
          errorMessage={phoneErrorMsg}
          className={styles.phoneInput}
        />
      </div>
    </div>
  );
};

export default InternationalPhonePicker;
