import { apiEndpoint } from 'mypages-library';
import { getRedirectTarget } from './redirectTarget';

export const getQueryParam = (key, search = window.location.search) => {
  const query = new URLSearchParams(search);
  return query.get(key) ?? null;
};

export const getRedirectValue = () =>
  getQueryParam('redirect') === null ? null : window.atob(getQueryParam('redirect'));

export const getTargetRedirectValue = async language => {
  const targetName = getQueryParam('target', window.location.search);
  if (targetName !== null) {
    const targetId = getQueryParam('targetid', window.location.search);
    const redirectTarget = await getRedirectTarget(targetName, targetId, language);
    if (redirectTarget !== null) {
      return redirectTarget;
    }
  }
  return null;
};

const getOauthBaseURL = () => {
  const environment = apiEndpoint.getCurrentEnvironment(window.location.hostname);
  const testEnvironmentHostname = 'galactica-oauth.dev.verisure.com';

  if (environment === 'test') {
    return testEnvironmentHostname;
  }

  return window.location.hostname.replace('www.', '');
};

export const getOAuthRedirectValue = () => {
  const oauthParam = getQueryParam('oauth');
  if (oauthParam === null) {
    return null;
  }

  return `https://${getOauthBaseURL()}/auth/login/?login_challenge=${oauthParam}&skipRedirect=true`;
};

export const isRedirectToWebshop = () =>
  getRedirectValue() === null
    ? false
    : getRedirectValue().search('webshop.html') > 0 || getRedirectValue().search('webshopcheckout.html') > 0;

const isOAuthRedirect = () => getQueryParam('oauth') !== null;

export const isLoginToMypages = () => {
  if (isOAuthRedirect()) {
    return false;
  }
  return true;
};
