import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import styles from './SimpleModal.module.scss';

function SimpleModal({ modalState, onClose, title, text, question, confirmBtn, cancelBtn }) {
  const { i18n } = useTranslation();
  const language = i18n.language || 'se';
  const url = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
  const forgotPasswordLink = `${url}/${language}/forgotpassword.html`;

  const openLink = () => {
    onClose();
    window.location.href = forgotPasswordLink;
  };

  const overlayColor = {
    overlay: {
      backgroundColor: 'rgba(41, 41, 41, 0.6)',
    },
  };

  return (
    <>
      <Modal
        isOpen={modalState}
        contentLabel="login-warning-modal"
        className={styles.modal}
        style={overlayColor}
        datatestid="simpleModal"
        ariaHideApp={false}
      >
        <p className={styles.title}>{title}</p>
        <div className={styles.scroll}>
          <p className={styles.text}>{text}</p>
          <p className={styles.question}>{question}</p>
        </div>
        <div className={styles.btnContainer}>
          <button onClick={openLink} className={styles.btnConfirm} type="button">
            {confirmBtn}
          </button>
          <button onClick={onClose} className={styles.btnCancel} type="button">
            {cancelBtn}
          </button>
        </div>
      </Modal>
    </>
  );
}

SimpleModal.propTypes = {
  modalState: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  question: PropTypes.string,
  confirmBtn: PropTypes.string,
  cancelBtn: PropTypes.string,
};

SimpleModal.defaultProps = {
  title: 'Warning',
  text: 'The password is incorrect. One attempt left before the account is temporarily locked.',
  question: 'Forgot your password or need to change it?',
  confirmBtn: 'YES',
  cancelBtn: 'NO',
};

export default SimpleModal;
