import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner } from 'mypages-library';
import { useAuthenticate } from 'common/hooks/useAuthenticate';
import { t } from 'i18n/translate';
import { Input } from 'components/Input/Input';
import UnsupportedBrowser from 'components/UnsupportedBrowser/UnsupportedBrowser';
import { browserIsUnsupported } from 'components/UnsupportedBrowser/unsupportedBrowserUtils';
import { isLoginToMypages } from 'common/urlParser';
import SimpleModal from 'components/SimpleModal/SimpleModal';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import PasswordInput from 'components/Input/PasswordInput';
import { getWebshopCreateAccountUrl, getWebshopForgotPasswordUrl } from 'utils/webshopAccountUtil';
import styles from './LoginWebshop.module.scss';

function LoginWebshop({ setIsMultiFactorAuthentication, emailController }) {
  const { email, setEmail } = emailController;
  const [password, setPassword] = useState('');
  const validInput = Boolean(email) && Boolean(password);
  const { isMultiFactorAuthentication, isLoading, errorBody, authenticate } = useAuthenticate();
  const [modalState, setModalState] = useState(false);
  const [successfulLogin, setSuccessfulLogin] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const redirectToEcommerce = !isLoginToMypages();

  const onCloseModal = () => {
    setModalState(false);
  };

  const handleModal = () => {
    if (errorBody.errorCode === 'AUT_00001' && errorBody.verified !== 'false' && attempts === 1) {
      setModalState(true);
    }
  };

  const isUnsupportedBrowser = browserIsUnsupported();

  const handleEnterKey = event => {
    if (event.key === 'Enter' && validInput && !isLoading) {
      authenticate(email, password, setSuccessfulLogin, redirectToEcommerce);
    }
  };

  useEffect(() => {
    if (errorBody) {
      setPassword('');
      handleModal();
      setAttempts(current => current + 1);
    }
  }, [errorBody]);

  useEffect(() => {
    setAttempts(0);
  }, [email]);

  useEffect(() => {
    if (successfulLogin) {
      setAttempts(0);
      setSuccessfulLogin(false);
    }
  }, [successfulLogin]);

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKey, { once: true });

    return () => document.removeEventListener('keydown', handleEnterKey);
  });

  useEffect(() => {
    setIsMultiFactorAuthentication(isMultiFactorAuthentication);
  }, [isMultiFactorAuthentication, setIsMultiFactorAuthentication]);

  return (
    <>
      <div className={styles['login-wrapper']}>
        <main id="LoginPage" className={styles['login-content']}>
          {isLoading && <LoadingSpinner isLoading centered size="large" dataTestId="spinner" />}
          <SubsidiaryAppLogo />
          {isUnsupportedBrowser ? (
            <UnsupportedBrowser />
          ) : (
            <>
              <Input
                disableLabelAnimation
                type="text"
                placeholder={t('MPL_LOGIN_PLACEHOLDER_EMAIL')}
                onChange={value => {
                  setEmail(value.trim());
                }}
                value={email}
                maxLength={255}
                className={styles['login-field']}
                dataTestId="mypages-login-email"
                autoComplete="username"
                minLengthErrorText={t('MPL_NOT_ENOUGH_CHARACTERS_ERROR')}
                mandatoryInfoText={t('MPL_ETC_MANDATORY_INPUT_FIELD')}
              />
              <PasswordInput
                disableLabelAnimation
                placeholder={t('MPL_LOGIN_PLACEHOLDER_PASSWORD')}
                onChange={value => setPassword(value)}
                value={password}
                maxLength={30}
                className={styles['login-field']}
                dataTestId="mypages-login-password"
                autoComplete="current-password"
              />
              {modalState && (
                <SimpleModal
                  onClose={onCloseModal}
                  modalState={modalState}
                  dataTestId="mypages-login-modal"
                  title={t('MPL_FORGOT_PASSWORD_WARNING_TITLE')}
                  text={t('MPL_FORGOT_PASSWORD_WARNING_TEXT')}
                  question={t('MPL_FORGOT_PASSWORD_WARNING_QUESTION')}
                  confirmBtn={t('MPL_FORGOT_PASSWORD_WARNING_CONFIRM_BUTTON')}
                  cancelBtn={t('MPL_FORGOT_PASSWORD_WARNING_CANCEL_BUTTON')}
                />
              )}
              <a href={getWebshopForgotPasswordUrl()} className={styles['forgot-pswd-link']}>
                {t('MPL_FORGOT_PASSWORD')}
              </a>
              {errorBody && <div className={styles['error-message']}>{errorBody.message}</div>}
              <Button
                text={t('MPL_LOGIN')}
                className={styles['login-button']}
                red
                onClick={() => {
                  authenticate(email, password, setSuccessfulLogin, redirectToEcommerce);
                }}
                disabled={!validInput || isLoading}
                dataTestId="mypages-login-submit"
              />
              <p className={styles['sign-up-text']}>
                {t('MPL_MISS_ACCOUNT')} <a href={getWebshopCreateAccountUrl()}> {t('MPL_SIGN_UP')}</a>
              </p>
            </>
          )}
        </main>
      </div>
    </>
  );
}

LoginWebshop.propTypes = {
  setIsMultiFactorAuthentication: PropTypes.func.isRequired,
  emailController: PropTypes.shape({
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
  }).isRequired,
};

LoginWebshop.whyDidYouRender = {
  customName: 'Login',
};

export default LoginWebshop;
