/* eslint-disable no-restricted-syntax */
const isoToMypages = {
  'da-dk': { code: 'dk', lookupType: 'short' },
  'de-de': { code: 'de', lookupType: 'short' },
  'en-gb': { code: 'uk', lookupType: 'short' },
  'fi-fi': { code: 'fi', lookupType: 'short' },
  'fr-fr': { code: 'fr', lookupType: 'full' },
  'fr-be': { code: 'frbe', lookupType: 'full' },
  'nl-nl': { code: 'nl', lookupType: 'full' },
  'nl-be': { code: 'nlbe', lookupType: 'full' },
  'no-no': { code: 'no', lookupType: 'short' },
  'sv-se': { code: 'se', lookupType: 'short' },
};

export const mapNavigatorLanguages = (fallback = 'se') => {
  const browserLanguages = navigator.languages || [navigator.language];

  const normalizedLanguages = browserLanguages.map(lang => lang.replace('_', '-').toLowerCase());

  for (const normalizedLang of normalizedLanguages) {
    for (const [iso, { code, lookupType }] of Object.entries(isoToMypages)) {
      if (lookupType === 'short' && normalizedLang.startsWith(iso.slice(0, 2))) {
        return code;
      }
      if (normalizedLang === iso) {
        return code;
      }
    }
  }
  return fallback;
};
const getLangFromBrowser = {
  name: 'getLangFromBrowser',

  lookup() {
    return mapNavigatorLanguages();
  },
};

export default getLangFromBrowser;
