import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from 'mypages-library';
import { t } from 'i18n/translate';
import { useRequestToken } from 'common/hooks/useRequestToken';
import styles from './TooManySMSSent.module.scss';

function WrongPhoneNumber({ setRequestView, setDataResponse }) {
  const { isLoading, error, requestToken } = useRequestToken(setRequestView, setDataResponse);

  return (
    <>
      <div className={styles['popup-overlay']} />
      <div id="wrongPhoneNumber" className={styles['popup-container']}>
        <main className={styles['popup-box']}>
          {isLoading && <LoadingSpinner isLoading centered size="large" />}
          <h1 className={styles.title}>{t('MPL_ALL_SMS_CONSUMED_TITLE')}</h1>
          <p className={styles.description}>{t('MPL_WRONG_PHONE_DESCRIPTION')}</p>
          <span className={styles['call-to-action']} id="requestEmailToken" onClick={() => requestToken('email')}>
            {t('MPL_OKAY')}
          </span>
          {error && <div className={styles['error-message']}>{error}</div>}
        </main>
      </div>
    </>
  );
}

WrongPhoneNumber.propTypes = {
  setRequestView: PropTypes.func.isRequired,
  setDataResponse: PropTypes.func.isRequired,
};
export default WrongPhoneNumber;
