import React from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner } from 'mypages-library';
import { t } from 'i18n/translate';
import { useRequestToken } from 'common/hooks/useRequestToken';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';

import styles from './MultiFactorAuthentication.module.scss';

function RequestAgain({ setRequestView, setDataResponse, email }) {
  const { isLoading, error, requestToken } = useRequestToken(setRequestView, setDataResponse);

  return (
    <div data-testid="requestAgain" className={styles['login-wrapper']}>
      <main className={styles['login-content']}>
        {isLoading && <LoadingSpinner isLoading centered size="large" />}
        <SubsidiaryAppLogo />
        <p className={styles.title}>{t('MPL_REQUEST_AGAIN_TITLE')} </p>
        <p className={styles['text-no-top-margin']}>{t('MPL_REQUEST_AGAIN_DESCRIPTION')}</p>
        {error && <div className={styles['error-message']}>{error}</div>}
        <p className={styles['text-sub']}>{t('MPL_REQUEST_AGAIN_SHARING', { email })} </p>
        <Button
          text={t('MPL_REQUEST_AGAIN_REQUEST_EMAIL_CODE')}
          className={styles['validate-button']}
          white
          onClick={() => requestToken('email')}
          dataTestId="requestAgainEmail"
        />
        <p className={styles['call-text']}>{t('MPL_REQUEST_AGAIN_OR')} </p>

        <p
          id="requestANewSmsToken"
          data-testid="requestAgainSMS"
          className={styles['missing-token-link']}
          onClick={() => requestToken()}
        >
          {t('MPL_REQUEST_AGAIN_REQUEST_SMS')}
        </p>
        <hr className={styles.divider} />
        <span className={styles['go-back-link']} onClick={() => setRequestView('validateToken')}>
          {t('MPL_GO_BACK')}
        </span>
      </main>
    </div>
  );
}

RequestAgain.propTypes = {
  setRequestView: PropTypes.func.isRequired,
  setDataResponse: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};
export default RequestAgain;
