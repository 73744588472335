import { gql } from '@apollo/client';

export const getCurrentUserQuery = gql`
  query CallingUser {
    callingUser {
      userName
      impersonatorUserName
    }
  }
`;
