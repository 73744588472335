import { ga } from 'mypages-library';
import { getRedirectValue, getTargetRedirectValue, getOAuthRedirectValue } from './urlParser';

export const navigationUrl = async (language: string) => {
  // Check if we have an oauth redirect url, this has the highest priority
  const oauthUrl = getOAuthRedirectValue();
  if (oauthUrl !== null) {
    return oauthUrl;
  }

  // Check if we have a redirect target
  const targetUrl = await getTargetRedirectValue(language);
  if (targetUrl !== null) {
    return targetUrl;
  }

  let url = `/${language}/status`;

  // For backwards compatibility we need to support the old redirect parameter
  // This should be removed when the old redirect parameter is no longer used by any clients
  const redirectUrl = getRedirectValue();
  if (redirectUrl) {
    url = redirectUrl;
    if (url.startsWith('/')) {
      url = `/${language}${url}`;
    } else {
      url = `/${url}`;
    }
  }
  return url;
};

export const navigateTo = async (language: string) => {
  ga.trackEvent({
    eventCategory: 'open',
    eventAction: 'mypages',
  });
  const url = await navigationUrl(language);
  window.location.replace(url);
};
