import { LoadingSpinner } from 'mypages-library';
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { initStates, useInitialize } from 'common/hooks/useInitialize';
import { useAuthenticate } from 'common/hooks/useAuthenticate';

interface RedirectProps {}
const Redirect: React.FC<RedirectProps> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { state: initState } = useInitialize();
  const ticket = queryParams.get('ticket');
  const giid = queryParams.get('installation');
  const { authenticateLoginTicket, errorBody: authError } = useAuthenticate();
  const navigate = useHistory();

  // Fallback to normal login page if there is any kind of auth error or no ticket provided
  const useNormalLoginFallback = initState === initStates.DONE && (!ticket || authError);

  useEffect(() => {
    if (ticket && initState === initStates.DONE) {
      authenticateLoginTicket(ticket);
    }
  }, [ticket, initState]);

  useEffect(() => {
    if (useNormalLoginFallback) {
      const isDeepLink = !ticket && !giid;
      const forwardParams = new URLSearchParams(location.search);
      if (isDeepLink) {
        forwardParams.set('deeplink', 'true');
      }
      forwardParams.delete('ticket');

      navigate.push(`/login${forwardParams.toString() ? '?' : ''}${forwardParams.toString()}`);
    }
  }, [useNormalLoginFallback]);

  return (
    <div>
      <LoadingSpinner isLoading centered size="large" dataTestId="spinner" />
    </div>
  );
};

export default Redirect;
