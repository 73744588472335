import { t } from 'i18n/translate';
import errorMessages from './errorMessages';

export const getErrorMessage = errorCode => {
  const key = errorMessages[errorCode]?.description || 'MPL_ETC_ERROR_OCCURED_TRY_AGAIN';
  return t(key);
};

export const parseError = response => {
  const errorBody = {
    message: '',
    errorCode: '',
    verified: '',
  };
  try {
    return response.json().then(body => {
      errorBody.errorCode = body?.errorCode;
      errorBody.verified = body?.verified;

      if (body?.verified === 'false') {
        errorBody.message = t('MPL_ACCOUNT_NOT_VERIFIED');
        return errorBody;
      }

      if (body?.tooManyStepUpTokens === 'true') {
        errorBody.message = t('MPL_TOO_MANY_STEPUP_TOKENS');
        return errorBody;
      }

      errorBody.message = getErrorMessage(body?.errorCode);
      return errorBody;
    });
  } catch (error) {
    errorBody.message = t('MPL_ETC_ERROR_OCCURED_TRY_AGAIN');
    errorBody.errorCode = 'CAUGHT_ERROR';
    errorBody.verified = 'false';
    return errorBody;
  }
};
