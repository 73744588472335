import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n/translate';
import { useAlternateSubsidiary } from 'common/hooks/useAlternateSubsidiary';

import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import styles from './MultiFactorAuthentication.module.scss';

function TooManyTokensRequested({ backToLogin }) {
  const { getPhoneNumber } = useAlternateSubsidiary();
  const phoneNumber = getPhoneNumber();

  return (
    <div id="TooManyTokensRequested" className={styles['login-wrapper']}>
      <main className={styles['login-content']}>
        <SubsidiaryAppLogo />
        <p className={styles.title}>{t('MPL_TOO_MANY_TOKEN_REQUESTS_ATTEMPTED')}</p>
        <p className={styles.text}>{t('MPL_PLEASE_RETURN_TO_START_AND_BEGIN_AGAIN')}</p>
        <p className={styles['call-text']}>{t('MPL_REQUEST_AGAIN_OR_CALL')} </p>
        <p className={styles['phone-number']}>{phoneNumber}</p>
        <hr className={styles.divider} />
        <span className={styles['go-back-link']} id="goBackToLogin" onClick={backToLogin}>
          {t('MPL_GO_BACK_TO_LOGIN')}
        </span>
      </main>
    </div>
  );
}

TooManyTokensRequested.propTypes = {
  backToLogin: PropTypes.func.isRequired,
};
export default TooManyTokensRequested;
