import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWithFailover } from 'default-client/DefaultClient';
import { getLocale } from 'common/languageUtil';

const getHeaders = () => ({
  APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
});

const useGetDealerSettings = () => {
  const { i18n } = useTranslation();
  const language = i18n.language || 'se';
  const [dealerSettings, setDealerSettings] = useState({});
  const getFormattedLocale = () => {
    const locale = getLocale(language);
    return locale === 'no' ? 'no_no' : locale?.replace('-', '_').toLowerCase().trim();
  };
  const locale = getFormattedLocale();

  useEffect(() => {
    const getDealerSettings = async () => {
      try {
        const headers = getHeaders();
        const url = `/dealersettings?locale=${locale}`;
        const response = await fetchWithFailover(url, {
          method: 'GET',
          headers,
        });
        const dealerData = await response.json();
        setDealerSettings(dealerData);
      } catch (error) {
        // ignore
      }
    };
    if (locale) {
      getDealerSettings();
    }
  }, [locale]);

  return { dealerSettings };
};

export default useGetDealerSettings;
