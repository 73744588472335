import React, { useState } from 'react';
import Login from 'components/Login/Login';
import LoginWebshop from 'components/LoginWebshop/LoginWebshop';
import MultiFactorAuthentication from 'components/MultiFactorAuthentication/MultiFactorAuthentication';
import { isLoginToMypages } from 'common/urlParser';

function App() {
  const [isMultiFactorAuthentication, setIsMultiFactorAuthentication] = useState(false);
  const [email, setEmail] = useState('');
  const redirectToEcommerce = !isLoginToMypages();

  const loginComponent = redirectToEcommerce ? (
    <LoginWebshop
      setIsMultiFactorAuthentication={setIsMultiFactorAuthentication}
      emailController={{ email, setEmail }}
    />
  ) : (
    <Login setIsMultiFactorAuthentication={setIsMultiFactorAuthentication} emailController={{ email, setEmail }} />
  );

  return <>{isMultiFactorAuthentication ? <MultiFactorAuthentication email={email} /> : loginComponent}</>;
}
App.whyDidYouRender = {
  customName: 'App',
};
export default App;
