import React from 'react';
import { AppLogo } from 'mypages-library';
import { useAlternateSubsidiary } from 'common/hooks/useAlternateSubsidiary';

function SubsidiaryAppLogo() {
  const { subsidiary } = useAlternateSubsidiary();

  return <AppLogo subsidiary={subsidiary} />;
}

export default SubsidiaryAppLogo;
